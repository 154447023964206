<template>
  <div class="main-conent main-conent-minheight">
    <el-row :gutter="24">
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
        <el-card class="box-card" shadow="never">
          <div slot="header">
            <span>开机设备</span>
            <el-tooltip effect="dark" content="正在运行的设备" placement="top">
              <svg-icon icon-class="ask" class="float-right cursor-pointer" />
            </el-tooltip>
          </div>
          <div class="num">{{onlinenum}}</div>
          <el-divider></el-divider>
          <el-row class="bot">
            <el-col :span="12">开机率</el-col>
            <el-col :span="12" class="text-right">{{(onlinenum/count).toFixed(2) * 1000 / 10}} %</el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
        <el-card class="box-card" shadow="never">
          <div slot="header">
            <span>停机设备</span>
            <el-tooltip effect="dark" content="已经停机的设备" placement="top">
              <svg-icon icon-class="ask" class="float-right cursor-pointer" />
            </el-tooltip>
          </div>
          <div class="num">{{offnum}}</div>
          <el-divider></el-divider>
          <el-row class="bot">
            <el-col :span="12">停机率</el-col>
            <el-col :span="12" class="text-right">{{(offnum/count).toFixed(2) * 1000/10}} %</el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
        <el-card class="box-card" shadow="never">
          <div slot="header">
            <span>今日开机时间</span>
            <el-tooltip effect="dark" content="转化率" placement="top">
              <svg-icon icon-class="ask" class="float-right cursor-pointer" />
            </el-tooltip>
          </div>
          <div class="num">{{(ontime/60).toFixed(0)+'小时'+ ontime%60 + '分钟'}}</div>
          <el-divider></el-divider>
          <el-row class="bot">
            <el-col :span="12">总开机率 </el-col>
            <el-col :span="12" class="text-right">{{(ontime/(Zplan_work_time*60)).toFixed(2) * 1000/10}}%</el-col>
          </el-row>
        </el-card>
      </el-col>

    </el-row>
    <!-- pc -->
    <el-card class="box-card status  isphone" shadow="never">
      <div class="title">
        设备状态

        <span class="statusSpan">总设备：{{count}}台</span>
        <span class="statusSpan off">停机设备：{{offnum}}台</span>
        <span class="statusSpan">运行设备：{{onlinenum}}台</span>
      </div>
      <div v-for="(item,index) in list" v-show="item.devices.length != 0" :key="index">
        <div style="    margin-bottom: 10px;margin-top:10px;">
          <el-button type="primary">{{item.group_name}}</el-button>
        </div>

        <div class="facilityBox" :class="item2.status == 'offline'? 'off':''" v-for="(item2,index) in item.devices" :key="index" @click="device(item2.iot_id)">

          <p>{{item2.nick_name}}</p>
          <p v-if="item2.status == 'offline'">
            停机时间
            <br />
            {{item2.last_off_time}}
          </p>
          <p v-else>
            开机时间
            <br />
            {{item2.last_online_time}}
          </p>

          <p v-if="item2.status == 'offline'">已停机{{disposeDate(item2.last_off_time)}}</p>
          <p v-else>已运行{{disposeDate(item2.last_online_time)}}</p>
          <div v-show="item2.is_expire" class="tj">
            <p>{{item2.nick_name}}</p>
            <p>此机器已到期</p>
            <p>点击续费</p>

          </div>
        </div>
      </div>
      <div style="    text-align: center;">
        <el-button type="primary" round size="medium" @click="all">进入全屏监控模式</el-button>
      </div>
    </el-card>
    <el-card class="box-card status ispc" shadow="never">
      <div class="title">
        设备状态
        <div style="width: 300px;
    display: inline-block;
    margin-left: 20px;" class="inbox">
          <el-date-picker value-format="yyyy-MM-dd" :picker-options="pickerOptions0" v-model="log_date" type="date" :clearable="false" placeholder="选择日期"></el-date-picker>
          <span @click="getzonlist" style="cursor:pointer;margin-left: 10px;color: #2a88b7;font-weight: none;" class="cx">查询</span>
        </div>

        <span class="statusSpan">总设备：{{count}}台</span>
        <span class="statusSpan off">停机设备：{{offnum}}台</span>
        <span class="statusSpan">运行设备：{{onlinenum}}台</span>
      </div>
      <div v-for="(item,index) in zonglist.group" v-show="item.devices.length != 0" :key="index">
        <div style="    margin-bottom: 10px;margin-top:10px;">
          <el-button type="primary">{{item.group_name}}</el-button>
        </div>
        <div class="line" v-for="(item2,index2) in item.devices" :key="index2">
          <span style="font-weight: bold;">{{item2.iot.nick_name}}</span>
          <strip :list="item2.onlieTimes" :end="end"></strip>
          <span style="float:right;">开机率：{{(item2.iot.total_online_time/(item2.iot.total_online_time+item2.iot.total_off_time) *100).toFixed(2)}}%</span>
        </div>
        <!-- <div class="facilityBox" :class="item2.status == 'offline'? 'off':''" v-for="(item2,index) in item.devices" :key="index" @click="device(item2.iot_id)">

          <p>{{item2.nick_name}}</p>
          <p v-if="item2.status == 'offline'">
            停机时间
            <br />
            {{item2.last_off_time}}
          </p>
          <p v-else>
            开机时间
            <br />
            {{item2.last_online_time}}
          </p>

          <p v-if="item2.status == 'offline'">已停机{{disposeDate(item2.last_off_time)}}</p>
          <p v-else>已运行{{disposeDate(item2.last_online_time)}}</p>
          <div v-show="item2.is_expire" class="tj">
            <p>{{item2.nick_name}}</p>
            <p>此机器已到期</p>
            <p>点击续费</p>

          </div>
        </div> -->
      </div>
      <div style="    text-align: center;margin-top: 50px;">
        <el-button type="primary" round size="medium" @click="all">进入全屏监控模式</el-button>
      </div>
    </el-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import strip from "@/components/echarts/strip";
import echarts from "echarts";
require("echarts/theme/macarons"); // echarts theme
import { debounce } from "@/utlis";
import { getMonth } from "@/utlis/date";
import { stoken, bind, onelist } from "@/service/api";
import { setToken } from "@/service/lib/localToken";
import Cookies from 'js-cookie';
export default {
  name: "Home",
  components: {
    strip
  },
  data () {
    return {
      end: {
        start: '00:00',
        end: '23:59'
      },
      log_date: new Date().toISOString().substring(0, 10),
      pickerOptions0: {
        disabledDate (time) {
          //8.64e7
          // return time.getTime() < Date.now() - 8.64e7; //只能选择今天及今天之后
          //return time.getTime() > Date.now() - 8.64e7; //只能选择今天之前，连今天都不能选

          //8.64e6
          // return time.getTime() < Date.now() - 8.64e6; //只能选择今天之后，连今天都不能选
          return time.getTime() > Date.now(); // 选当前时间之前的时间
        }
      },
      count: 0,
      zonglist: [],
      isall: false,
      username: Cookies.get('userNane'),
      logo: Cookies.get('logo'),
      ws: null,
      date: new Date().valueOf(),
      chart: null,
      resizeHandler: null,
      radio1: "今日",
      datevalue1: "",
      chartList: [
        {
          list: [
            { value: 235, name: "开机" },
            { value: 274, name: "停机" }
          ]
        },
        {
          list: [
            { value: 50, name: "开机" },
            { value: 80, name: "停机" }
          ]
        },
        {
          list: [
            { value: 90, name: "开机" },
            { value: 80, name: "停机" }
          ]
        }
      ],
      list: [],
      onlinenum: "",
      offnum: "",
      ontime: 0,
      //断开链接弹窗
      isws: true
    };
  },
  computed: {
    ...mapGetters(["sidebarOpened"]),
    Zplan_work_time: function () {
      let zong = 0;
      this.list.forEach(item => {
        zong = zong + (item.plan_work_time * item.devices.length);
      });
      return zong;
    }
  },
  watch: {
    sidebarOpened () {
      this.resizeHandler();
    }
  },

  methods: {
    all () {
      //全屏
      // var docElm = document.documentElement;
      // //W3C
      // if (docElm.requestFullscreen) {
      //   docElm.requestFullscreen();
      // }

      // //FireFox
      // else if (docElm.mozRequestFullScreen) {
      //   docElm.mozRequestFullScreen();
      // }

      // //Chrome等
      // else if (docElm.webkitRequestFullScreen) {
      //   docElm.webkitRequestFullScreen();
      // }

      // //IE11
      // else if (docElm.msRequestFullscreen) {
      //   docElm.msRequestFullscreen();
      // }
      // this.isall = true;
      this.$router.push('home');
    },
    offall () {
      // if (document.exitFullscreen) {
      //   document.exitFullscreen();
      // }
      // //FireFox
      // else if (document.mozCancelFullScreen) {
      //   document.mozCancelFullScreen();
      // }
      // //Chrome等
      // else if (document.webkitCancelFullScreen) {
      //   document.webkitCancelFullScreen();
      // }
      // //IE11
      // else if (document.msExitFullscreen) {
      //   document.msExitFullscreen();
      // }
      this.$router.push('/home?type=2');
    },
    Achart () {
      this.chartList.forEach((item, index) => {
        console.log(item);
        let chartDom = document.getElementById("Achart" + index);
        let myChart = echarts.init(chartDom);
        myChart.setOption({
          series: [
            {
              name: "开机时间",
              type: "pie",
              radius: "80%",
              data: item.list
            }
          ]
        });
      });
    },
    initChart () {
      // this.chart = echarts.init(document.getElementById('home-traffic-chart'),'macarons');

      let app = {};

      let chartDom = document.getElementById("home-traffic-chart");
      let myChart = echarts.init(chartDom);
      let option;

      let cellSize = [80, 80];
      let pieRadius = 30;

      function getVirtulData () {
        console.log(getMonth(0), getMonth(1));
        let date = +echarts.number.parseDate(getMonth(0));
        console.log(date);
        let end = +echarts.number.parseDate(getMonth(1));
        let dayTime = 3600 * 24 * 1000;
        let data = [];
        for (let time = date; time < end; time += dayTime) {
          data.push([
            echarts.format.formatTime("yyyy-MM-dd", time),
            Math.floor(Math.random() * 10000)
          ]);
        }
        return data;
      }

      function getPieSeries (scatterData, chart) {
        return scatterData.map(function (item, index) {
          let center = chart.convertToPixel("calendar", item);
          return {
            id: index + "pie",
            type: "pie",
            center: center,
            label: {
              normal: {
                formatter: "{c}",
                position: "inside"
              }
            },
            radius: pieRadius,
            data: [
              { name: "开机", value: Math.round(Math.random() * 24) },
              { name: "关机", value: Math.round(Math.random() * 24) }
            ]
          };
        });
      }

      function getPieSeriesUpdate (scatterData, chart) {
        return scatterData.map(function (item, index) {
          let center = chart.convertToPixel("calendar", item);
          return {
            id: index + "pie",
            center: center
          };
        });
      }

      let scatterData = getVirtulData();

      option = {
        tooltip: {},
        legend: {
          data: ["开机", "关机"],
          bottom: 20
        },
        calendar: {
          top: "middle",
          left: "center",
          orient: "vertical",
          cellSize: cellSize,
          yearLabel: {
            show: false,
            fontSize: 30
          },
          dayLabel: {
            margin: 20,
            firstDay: 1,
            nameMap: [
              "星期日",
              "星期一",
              "星期二",
              "星期三",
              "星期四",
              "星期五",
              "星期六"
            ]
          },
          monthLabel: {
            show: false
          },
          range: [getMonth(2)]
        },
        series: [
          {
            id: "label",
            type: "scatter",
            coordinateSystem: "calendar",
            symbolSize: 1,
            label: {
              show: true,
              formatter: function (params) {
                return echarts.format.formatTime("dd", params.value[0]);
              },
              offset: [-cellSize[0] / 2 + 10, -cellSize[1] / 2 + 10],
              fontSize: 14
            },
            data: scatterData
          }
        ]
      };

      let pieInitialized;
      setTimeout(function () {
        pieInitialized = true;
        myChart.setOption({
          series: getPieSeries(scatterData, myChart)
        });
      }, 10);

      app.onresize = function () {
        if (pieInitialized) {
          myChart.setOption({
            series: getPieSeriesUpdate(scatterData, myChart)
          });
        }
      };

      option && myChart.setOption(option);
    },
    initResizeEvent () {
      window.addEventListener("resize", this.resizeHandler);
    },
    // c
    initWebSocket () {
      let taht = this;
      if ("WebSocket" in window) {
        console.log("准备链接");
        taht.ws = new WebSocket("ws://106.15.90.16:12345");
        taht.ws.onopen = function () {
          console.log(taht.ws);
        };
        taht.ws.onerror = function () {
          console.log("链接失败");
        };
        taht.ws.onmessage = function (e) {
          console.log("收到服务端的消息：" + e.data);

          taht.setjson(e.data);
        };
        taht.ws.onclose = function (e) {
          console.log("断开链接" + e);

          if (taht.isws) {
            console.log("重新链接");
            taht.$alert("请检查网络后，重新链接", "网络错误", {
              confirmButtonText: "确定",
              callback: () => {
                location.reload();
              }
            });
          }
        };
      } else {
        alert("此浏览器不支持WebSocket");
      }
    },

    // 时间处理换算
    dateConvert (value) {
      let hour,
        min,
        sec,
        createDate = value / 1000;
      hour = parseInt(createDate / 60 / 60);
      min = parseInt((createDate - hour * 3600) / 60);
      sec = parseInt(createDate - (hour * 3600 + min * 60));
      if (hour > 0) {
        if (min > 0) {
          if (sec > 0) {
            createDate = hour + "时" + min + "分" + sec + "秒";
          } else {
            createDate = hour + "时" + min + "分";
          }
        } else {
          if (sec > 0) {
            createDate = hour + "时" + sec + "秒";
          } else {
            createDate = hour + "时";
          }
        }
      } else if (min > 0) {
        createDate = sec > 0 ? min + "分" + sec + "秒" : min + "分";
      } else if (sec > 0) {
        createDate = sec + "秒";
      }
      return createDate;
    },
    disposeDateM (itme) {
      let newitme = this.date - new Date(itme).getTime();

      let hour,
        min,
        sec,
        createDate = newitme / 1000;
      hour = parseInt(createDate / 60 / 60);
      min = parseInt((createDate - hour * 3600) / 60);
      sec = parseInt(createDate - (hour * 3600 + min * 60));
      let data = {
        hour,
        min,
        sec,
        createDate
      };
      return data;
    },
    disposeDate (itme) {
      let newitme = this.date - new Date(itme).getTime();

      return this.dateConvert(newitme);
    },
    // json 处理
    setjson (data) {
      data = JSON.parse(data);
      if (data.type === 'init') {
        console.log('初始化');
        let sdata = {
          client_id: data.client_id
        };
        bind(sdata).then(res => {
          console.log(res);
          if (res.code === 200) {
            console.log(this.ws);
            this.ws.send('{"mode":"group"}');
            console.log("链接成功");
          }

        });
        return;
      }
      if (data.code === 200) {
        console.log("初始消息");
        console.log(data);
        let list = data.data.group;
        console.log(typeof list);
        let zong = 0;
        list.forEach(item => {
          zong = zong + item.devices.length;
          item.devices.forEach(item2 => {
            if (item2.status === "online") {
              item2.xdate = this.disposeDateM(item2.last_online_time).min;
            } else {
              item2.xdate = 0;
            }
            if (item2.last_online_time) {
              item2.last_online_time = item2.last_online_time.substring(0, 19);
            }
            if (item2.last_off_time) {
              item2.last_off_time = item2.last_off_time.substring(0, 19);
            }
          });

        });
        this.count = zong;
        this.list = list;
        console.log(this.list);
      }
      if (data.msg === "ok") {
        console.log(data);

        let list = this.list;
        this.list.forEach((item, index) => {
          item.offline_count = data.data.group[index].offline_count;
          console.log(item.offnum);
          item.online_count = data.data.group[index].online_count;
          item.devices.forEach(item2 => {
            if (item2.iot_id === data.data.iot_id) {
              item2.xdate = 0;
              item2.status = data.data.status;
              item2.last_off_time = data.data.last_off_time.substring(0, 19);
              item2.last_online_time = data.data.last_online_time.substring(0, 19);
              item2.total_online_time = data.data.total_online_time;
              item2.total_off_time = data.data.total_off_time;
              console.log(item2);
            }
          });

        });
        console.log(list);
        this.list = list;

      }
      this.isoff();
    },
    // 判断几个上线
    isoff () {
      let off = 0;
      let onlin = 0;
      let ontime = 0;
      this.list.forEach(item => {
        item.devices.forEach(item2 => {
          if (item2.status === "offline") {
            off++;
          } else {
            onlin++;
          }
          // 计算开机时间
          ontime = ontime + item2.total_online_time;
        });

      });
      this.onlinenum = onlin;
      this.offnum = off;
      this.ontime = ontime;
      // this.ontime = parseInt(ontime / 60) + "小时" + (ontime % 60) + "分钟";
    },


    device (id) {
      console.log(id);
      this.$router.push("/one?id=" + id);
    },

    getzonlist () {
      if (this.log_date === new Date().toISOString().substring(0, 10)) {
        let min = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
        this.end.end = new Date().getHours() + ':' + min;
      } else {
        this.end.end = '23:59';
      }
      onelist({ log_date: this.log_date }).then(res => {
        console.log(res);
        let zong = this.log_date === new Date().toISOString().substring(0, 10) ? new Date().getHours() + ':' + new Date().getMinutes() : '23:59';
        zong = parseInt(zong.substring(0, 2)) * 60 + parseInt(zong.substring(3, 5));
        res.data.group.forEach(it => {
          it.devices.forEach(im => {
            im.onlieTimes.forEach(item => {
              item.startDate = item.start;
              item.ednDate = item.end;
              item.start = (parseInt(item.start.substring(0, 2)) * 60 + parseInt(item.start.substring(3, 5))) * 100 / zong;
              item.end = (parseInt(item.end.substring(0, 2)) * 60 + parseInt(item.end.substring(3, 5))) * 100 / zong;
            });
          });
        });
        this.zonglist = res.data;
      });

    }
  },
  created () {
    stoken().then(res => {
      const { token } = res.data;
      setToken(token);
      this.initWebSocket();
      this.getzonlist();

    });


  },
  destroyed () {
    this.isws = false;
    this.ws.close(); //离开路由之后断开websocket连接
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
    }
  },
  mounted () {
    const _this = this;
    _this.resizeHandler = debounce(() => {
      if (_this.chart) {
        _this.chart.resize();
      }
    }, 100);

    _this.initResizeEvent();
    // this.initChart();
    // this.Achart();
    this.timer = setInterval(() => {
      _this.date = new Date(); // 修改数据date
    }, 1000);



  }
};
</script>
<style lang="scss" scoped>
.v-modal {
  z-index: 1 !important;
}
.all {
  width: 100%;
  min-width: 1200px;
  height: 100%;
  overflow-y: scroll;
  position: fixed;

  z-index: 999;
  background-color: #292626;
  top: 0;
  left: 0;
}
.allhed {
  width: 100%;
  text-align: center;
  color: white;
  height: 100px;
  line-height: 100px;
  border-bottom: 3px solid white;
  padding: 0 30px;
  box-sizing: border-box;
  position: relative;
}
.username {
  font-size: 32px;
}
.offall {
  position: absolute;
  top: 110px;
  right: 0;
}
.allhed img {
  width: 40px;
  height: 40px;
  margin: 0px 10px;
  transform: translateY(10px);
}
.allhed .h3 {
  font-weight: bold;
  font-size: 50px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.allboxhed {
  color: white;
  margin-top: 20px;
  padding: 0 30px;
}
.boxmain {
  padding: 0 30px;
  box-sizing: border-box;
  margin: 30px;
}
.boxmain .san {
  position: absolute;
  top: 0;
  right: 0;

  width: 0;
  height: 0;
  border-top: 30px solid #292626;
  border-left: 30px solid transparent;
}
.allstrip {
  height: 20px;

  background-color: #85a34c;
}
.allstripbox {
  width: 80%;
  margin: 15px auto;
  height: 20px;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
  position: relative;
}
.allstripbox span {
  position: absolute;
  display: inline-block;
  width: 100%;
  top: 50%;
  left: 50%;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: black;
}
.boxmain .facilityBox {
  width: 180px;
  height: 220px;
  background-color: #1d843e;
  display: inline-block;
  margin-right: 50px;
  margin-bottom: 30px;
  text-align: center;
  cursor: pointer;
  color: white;
  font-size: 14px;
  position: relative;
}
.boxmain .facilityBox p {
  margin: 0;
}
.boxmain .off {
  background-color: #999ca5;
}
.boxmain .facilityBox p:nth-child(1) {
  line-height: 60px;
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}
.allboxhed span {
  padding: 0 5px;
  line-height: 28px;
  border-radius: 5px;
  display: inline-block;
  margin-right: 10px;
  font-size: 20px;
}
.allboxhed .section {
  background-color: #06acfa;
  font-size: 18px;
  font-weight: bold;
}
.allboxhed .onlin {
}
.status .title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 30px;
}
.statusSpan {
  margin-left: 30px;
  background-color: #35b72a;
  padding: 0px 20px;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  float: right;
}
.facilityBox {
  width: 150px;
  height: 150px;
  background-color: #35b72a;
  display: inline-block;
  margin-right: 50px;
  margin-bottom: 30px;
  text-align: center;
  cursor: pointer;
}
.status .off {
  background-color: #c8c8c8;
}
.facilityBox p:nth-child(1) {
  line-height: 60px;
  font-size: 18px;
  margin: 0;
}
.facilityBox p:nth-child(2) {
  line-height: 20px;
  font-size: 12px;
  margin: 0;
}
.facilityBox p:nth-child(3) {
  line-height: 30px;
  font-size: 14px;
  margin: 0;
}
.facilityBox p:nth-child(4) {
  line-height: 20px;
  font-size: 12px;
  margin: 0;
  margin-bottom: 10px;
}
.charttext {
  width: 300px;
  display: inline-block;
  text-align: center;
}
.chartbox {
  height: 100px;
  width: 300px;
}
.box-card {
  border: 0;
  margin-bottom: 24px;

  .el-card__header {
    padding-left: 12px;
    padding-right: 12px;
  }
  .el-card__body {
    padding: 12px;
  }
  .num {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .el-divider--horizontal {
    margin: 8px 0;
  }
  .bot {
    font-size: 14px;
  }
  .traffic-title {
    margin: 0;
    margin-bottom: 10px;
  }
  .traffic-li {
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    margin-top: 10px;
  }
  .traffic-num {
    width: 24px;
    height: 24px;
    line-height: 24px;
    margin-right: 8px;
    &.hot {
      background-color: rgba($color: #209e91, $alpha: 0.8);
      color: #ffffff;
    }
  }
}
@media screen and (max-width: 1600px) {
  .username {
    font-size: 24px;
  }
  .username img {
    height: 40px !important;
  }
  .allhed .h3 {
    font-size: 36px;
  }
}
.facilityBox {
  position: relative;
}
.tj {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #ff9f0e;
  top: 0;
  left: 0;
}

.line {
  height: 100px;
  line-height: 100px;
  padding: 0 20px;
  position: relative;
  .canvsbox {
    width: 80%;
    position: absolute;
    left: 10%;
    top: 25px;
  }
  .stripBox {
    margin: 0 !important;
  }
}
.ispc {
  display: block;
}
.isphone {
  display: none;
}
@media screen and (max-width: 750px) {
  .ispc {
    display: none;
  }
  .isphone {
    display: block;
  }
  .line {
    display: none;
    height: 100px;
    line-height: 100px;
    padding: 0 0px;
    position: relative;
    .canvsbox {
      width: 40%;
      position: absolute;
      left: 35%;
      top: 25px;
    }
    .stripBox {
      margin: 0 !important;
    }
  }
  .statusSpan {
    margin-left: 0px;
    background-color: #35b72a;
    padding: 0px 10px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 24px;
    font-weight: normal;
    float: initial;
    display: block;
    width: 100%;
    margin-top: 10px;
  }
  .title {
    .inbox {
      width: 100% !important;
      margin: 0 !important;
      margin-top: 10px !important;
    }
    input {
      width: 80%;
    }
  }
  .main-conent-minheight {
    min-height: 100% !important;
    margin: 10px;
  }
  .facilityBox {
    width: 42vw;
    height: 40vw;
    background-color: #35b72a;
    display: inline-block;
    margin-right: 0px;
    margin-bottom: 10px;
    text-align: center;
    cursor: pointer;
  }
  .facilityBox:nth-child(2n + 3) {
    float: right;
  }
  .indexlayout-right-footer[data-v-ad21c7f0] {
    padding: 0px 0 0px;
    height: 30px;
    overflow: hidden;
    text-align: center;
    font-size: 14px;
    color: #808695;
  }
}
</style>
